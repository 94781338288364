import { APP_INITIALIZER, NgModule } from '@angular/core';
import {
  ApiModule,
  FeatureFlagsModule,
  LOGGER_CONFIGURATION,
  LoggerConfiguration,
  LoggerModule,
  NcUrlSerializer,
  UserAccountModule,
  UserAccountService,
  UserModule,
  UserService,
  commonApiInterceptorProviders,
  initializeKeycloak,
  serviceUnavailableInterceptorProvider,
  websocketOptionsDI,
} from '@framework';
import { ENVIRONMENT, EnvironmentConfig } from '@abstractions';
import { HeaderModule, LoaderComponent, ToastContainer, logoutRedirectUriDI, toastServiceProvider } from '@ui';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { Observable, from } from 'rxjs';
import { RouterModule, UrlSerializer } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

import { AppComponent } from './app.component';
import { AppService } from '@utils';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { EffectsModule } from '@ngrx/effects';
import { EntityDataModule } from '@ngrx/data';
import { UserAccountService as FeedbackUserAccountService } from './customizations/framework/user/user-account.service';
import { UserService as FeedbackUserService } from './customizations/framework/user/user.service';
import { LocationStrategy } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { appRoutes } from './app.routes';
import { entityConfig } from './shared/store/entity-metadata';
import { environment } from '../environments/environment';
import { featureFlagsOptDiProvider } from './customizations/framework/feature-flags/feature-flags-di-provider';
import { toastButtonsConfigurationServiceProvider } from './customizations/ui/toast/toast-button-configuration.provider';
import { downloadDocumentOptDi } from '@features';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { providePrimeNG } from 'primeng/config';
import { MyPreset } from './mytheme';

export class CustomLoader implements TranslateLoader {
  getTranslation(langCountry: string): Observable<object> {
    const locale = import('../assets/i18n/' + langCountry + '.json');
    return from(locale);
  }
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    StoreModule.forRoot({}),
    EffectsModule.forRoot(),
    StoreRouterConnectingModule.forRoot(),
    KeycloakAngularModule,
    ApiModule,
    UserModule,
    UserAccountModule,
    LoggerModule,
    HeaderModule,
    EntityDataModule.forRoot(entityConfig),
    ToastContainer,
    LoaderComponent,
    RouterModule.forRoot(appRoutes),
    FeatureFlagsModule,
    TranslateModule.forRoot({
      defaultLanguage: 'cs',
      loader: {
        provide: TranslateLoader,
        useClass: CustomLoader,
      },
    }),
  ],
  providers: [
    AppService,
    featureFlagsOptDiProvider,
    {
      provide: downloadDocumentOptDi,
      useValue: `/api/latest/rest/feedback/files/#{fileKey}/download`,
    },
    toastButtonsConfigurationServiceProvider,
    toastServiceProvider,
    provideAnimationsAsync(),
    providePrimeNG({
      ripple: false,
      theme: {
        preset: MyPreset,
        options: {
          darkModeSelector: false || 'none',
          cssLayer: {
            name: 'primeng',
            order: 'basis, tailwind-base, primeng, tailwind-utilities',
          },
        },
      },
    }),
    ...commonApiInterceptorProviders,
    !environment.isProductionEnv ? serviceUnavailableInterceptorProvider : [],
    {
      provide: websocketOptionsDI,
      useValue: {
        connect: `${environment.websocketUriPrefix}/api/services/rest/socket/websocket`,
      },
    },
    { provide: UrlSerializer, useClass: NcUrlSerializer },
    {
      provide: LOGGER_CONFIGURATION,
      useFactory: (): LoggerConfiguration => {
        return {
          enabled: !environment.localhost,
          endpoint: `${environment.apiUriPrefix}/api/latest/rest/log`,
          appName: 'feedback',
        };
      },
    },
    {
      provide: ENVIRONMENT,
      useFactory: (): EnvironmentConfig => {
        return {
          environment: environment.environment,
          apiUriPrefix: environment.apiUriPrefix,
          keycloakUrl: environment.keycloakUrl,
          publicDirectUrl: environment.publicDirectUrl,
        };
      },
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService, LocationStrategy, UrlSerializer, ENVIRONMENT],
    },
    {
      provide: UserService,
      useClass: FeedbackUserService,
    },
    {
      provide: UserAccountService,
      useClass: FeedbackUserAccountService,
    },
    {
      provide: logoutRedirectUriDI,
      useValue: '',
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
